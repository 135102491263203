import React from 'react'
import '../App.css';

class Projects extends React.Component {
	
		
	
	render() {
		console.log("Projects");
		return(
        <div className="content">
          <h2>Projects</h2>
		  <h3>Showdown Referee App</h3>
		  An app for referees of showdown (kind of table tennis for the blind), which is available for both iOS and Android. The app can be used in trainings for ad-hoc
		  matches as well as in tournaments where it integrates with our tournament server (see below).
		  Further information on <a target="_new" href="https://sd-development.ch/showdown-referee">sd-development.ch/showdown-referee</a>
		  <h3>Showdown Tournament Server</h3>
		  The showdown tournament server allows for setting up match plans and automatic calculation of intermediate and final ranking
		  lists. If it is used together with our showdown referee app it also provides a live ticker for ongoing matches and it stores the whole match progress for later investigation This server, which
		  is developed, maintained, hosted and operated by SD-Development has been used in all the more than 20 official <a target="_new" href="https://sd-development.ch/showdown" target="_blank" rel="noopener noreferrer">Swiss tournaments</a> since 2017.
		  In November 2023 the application was used the first time for an <a href="https://sd-development.ch/international" target="_blank" rel="noopener noreferrer">international tournament</a>
        </div>
		)
	}
}
			

export default Projects;
